<template>
	<div style="padding-bottom: 20px;">
		<mobile-header active="/mobile/companyActive"></mobile-header>
		<h4 style="margin-bottom: 0;" class="font2">招投标咨询</h4>
		<p class="borderBottom"><span>&nbsp;</span></p>
		<div style="padding:0 20px;">
			<img src="@assets/images/companyActive/公司业务—招投标咨询.jpg" style="width: 100%;" />
		</div>
		<div style="padding:0 10px;color: #595959;font-size: 14px;line-height: 25px;">
			<p style="text-indent: 2em;">
				招投研究院具有专业的招投标咨询专家团队。他们具有丰富的招投标理论知识和实践经验，应用科学、前沿的方法，找出企业在招投标管理方面存在的问题，分析原因，提出改进方案，并指导企业将改进方案实施落地，改善企业的招投标管理水平。
			</p>
		</div>

		<div style="text-align: center;margin-top: 40px;">
			<h4 style="margin-bottom: 0;" class="font2">招标采购管理模型</h4>
			<p class="borderBottom"><span>&nbsp;</span></p>
			<img src="@assets/images/tenderSultation/u507.png"  style="width: 100%;" />
		</div>

		<div style="text-align: center;margin-top: 40px;">
			<h4 style="margin-bottom: 0;" class="font2">服务流程</h4>
			<p class="borderBottom"><span>&nbsp;</span></p>
			<div id="liuCheng">
				<div class="iconDiv">
					<p class="iconfont icon-kehuxuqiu white"></p>
					<h5>获知客户需求</h5>
				</div>
				<div class="iconDiv2">
					<p class="iconfont icon-jiantou"></p>
				</div>
				<div class="iconDiv">
					<p class="iconfont icon-qiandingxieyi white"></p>
					<h5>签订委托协议</h5>
				</div>
				<div class="iconDiv2">
					<p class="iconfont icon-jiantou"></p>
				</div>
				<div class="iconDiv">
					<p class="iconfont icon-shichangtiaoyan white"></p>
					<h5>进行市场调查</h5>
				</div>
				<div class="iconDiv2">
					<p class="iconfont icon-jiantou"></p>
				</div>
				<div class="iconDiv">
					<p class="iconfont icon-baogao white"></p>
					<h5>生成咨询报告</h5>
				</div>
				<div class="iconDiv2">
					<p class="iconfont icon-jiantou"></p>
				</div>
				<div class="iconDiv">
					<p class="iconfont icon-zhiliangkongzhi white"></p>
					<h5>项目质量控制</h5>
				</div>
				<div class="iconDiv2">
					<p class="iconfont icon-jiantou"></p>
				</div>
				<div class="iconDiv">
					<p class="iconfont icon-zhuanjialunzhenghuiyi white"></p>
					<h5>组织专家论证</h5>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import mobileHeader from "@components/mobileHeader";
	export default {
		name: 'tenderSultation',
		components: {
			mobileHeader,
		},
		data() {
			return {
				
			}
		},
		methods: {
		},
		mounted: function() {
			this.bus.$emit('headerImg', this.headerImgMobile);
		}
	}
</script>

<style scoped>
	.font2{
		background-image: -webkit-gradient( linear, left top, right top, color-stop(0.3, #22f), color-stop(0.6, #2ff) );
		color: transparent;
		-webkit-background-clip: text;
		text-align: center;
	}
	.borderBottom{
		text-align: center;
		margin:0 0 10px 0;
	}
	.borderBottom span{
		border-bottom:#FF8700 3px solid ;
		display: inline-block;
		width: 20px;
	}
	#liuCheng{
		display: flex;
		background-color:#409EFF;
		color: #fff;
		padding:0 15px;
		text-align: center;
	}
	#liuCheng .iconDiv{
		flex-grow: 2;width: 0;padding-top: 20px;
	}
	#liuCheng .iconDiv2{
		flex-grow: 1;width: 0;padding-top: 20px;
	}
	#liuCheng .iconDiv2 .icon-jiantou{
		font-size:20px;
	}
	#liuCheng .iconDiv .white{
		display:inline-block;
		width: 40px;
		height: 40px;
		line-height: 40px;
		border-radius: 50%;
		font-size:25px;
		background-color: #fff;
		box-sizing: border-box;
		margin: 0;
		color:#3281B9;
	}
</style>


